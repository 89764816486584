import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import { NavigationBar, Section, Footer, Seo } from "."
import { BetterTogether } from "./sections/BetterTogether"

interface Props {
  title?: string
  description?: string
  currentSection?: Section
  navigationBarVariant?: "default" | "grey"
  displayContactUs?: boolean
  cardImage?: string
}

export const Layout: React.FC<Props> = ({
  children,
  title,
  description,
  currentSection = "home",
  navigationBarVariant,
  displayContactUs = true,
  cardImage,
}) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery<{
    site: {
      siteMetadata?: {
        env: string
      }
    }
  }>(
    graphql`
      query {
        site {
          siteMetadata {
            env
          }
        }
      }
    `
  )

  return (
    <>
      {siteMetadata?.env === "production" ? (
        <Helmet/>
      ) : null}
      <NavigationBar
        currentSection={currentSection}
        variant={navigationBarVariant}
      />
      <Seo title={title} description={description} cardImage={cardImage} />
      <main className="overflow-hidden">{children}</main>
      {displayContactUs ? <BetterTogether /> : null}
      <Footer />
    </>
  )
}
