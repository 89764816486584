import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import lawnImage from "../images/homepage-raw-assets/Linkedin_img.png"

interface Props {
  description?: string
  lang?: string
  meta?: (
    | {
        name: string
        content: any
      }
    | {
        property: string
        content: any
      }
  )[]
  title?: string
  cardImage?: string
}

export const Seo: React.FC<Props> = ({
  description,
  lang = "en",
  meta = [],
  title,
  cardImage,
}) => {
  const { site } = useStaticQuery<{
    site: {
      siteMetadata?: {
        title: string
        description: string
        author: string
      }
    }
  }>(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata?.description
  const defaultTitle = site.siteMetadata?.title
  const concreteTitle = title || defaultTitle || "Livecycle"
  const absoluteImageUrl = `https://livecycle.io${cardImage ?? lawnImage}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={concreteTitle}
      titleTemplate={title ? `${defaultTitle} | %s` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: concreteTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `image`,
          property: `og:image`,
          content: absoluteImageUrl,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:site`,
          content: `@get_livecycle`,
        },
        {
          name: `twitter:creator`,
          content: `@get_livecycle`,
        },
        {
          name: `twitter:title`,
          content: concreteTitle,
        },
        {
          name: `twitter:image`,
          content: absoluteImageUrl,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `facebook-domain-verification`,
          content: `8pvhhoe0vup5120x6q60nricb2y0kj`,
        },
      ].concat(meta)}
    />
  )
}
