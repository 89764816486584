import React from "react"
import Logo from "../images/svg/logo.svg"
import LinkedInLogo from "../images/svg/logos/linkedin.svg"
import TwitterLogo from "../images/svg/logos/twitter.svg"
import DiscordLogo from "../images/svg/logos/discord.svg"
import { WithClassName } from "./types"

const LinkedIn: React.FC<WithClassName> = ({ className }) => {
  return <LinkedInLogo className={className} />
}

const Discord: React.FC<WithClassName> = ({ className }) => {
  return <DiscordLogo className={className} />
}

const Twitter: React.FC<WithClassName> = ({ className }) => {
  return <TwitterLogo className={className} />
}

const Link: React.FC<
  WithClassName<{ href: string; mr?: string; ml?: string }>
> = ({ children, className, mr = "mr-4", ml = "ml-0", href }) => {
  return (
    <a
      className={`text-base md:text-xs lg:text-base ${mr} ${ml} visited:text-black ${className}`}
      href={href}
    >
      {children}
    </a>
  )
}

export const Footer: React.FC = () => {
  return (
    <footer className="flex flex-col md:flex-row w-full justify-between items-center py-16 px-4 md:px-8 lg:px-12">
      <Logo className="mb-11 md:mb-0 h-9 sm:h-8 lg:h-10 filter brightness-0 md:brightness-100" />
      <nav>
        <div className="hidden md:flex flex-row justify-center flex-wrap px-8 my-auto">
          <Link href="/blog">Blog</Link>
          <Link href="https://docs.livecycle.io/">Documentation</Link>
          <Link href="https://get-livecycle.notion.site/Senior-Software-Engineer-61e3d0bb46cd4997ae82bdb36cd967fd">
            Careers
          </Link>
          <Link href="https://livecycle.dev/">Sign up</Link>
          <Link href="/terms-of-use">Terms of use</Link>
          <Link href="/privacy-policy">Privacy policy</Link>
        </div>
        <div className="md:hidden flex flex-col justify-center">
          <div className="flex flex-row justify-center mb-4 items-center">
            <Link mr="mr-2" href="https://livecycle.dev/">
              Sign up
            </Link>
            <hr className="border-l-1 border-solid border-black h-4" />
            <Link mr="mr-2" ml="ml-2" href="/blog">
              Blog
            </Link>
            <hr className="border-l-1 border-solid border-black h-4" />
            <Link
              mr="mr-0"
              ml="ml-2"
              href="https://get-livecycle.notion.site/Senior-Software-Engineer-61e3d0bb46cd4997ae82bdb36cd967fd"
            >
              Careers
            </Link>
          </div>
          <div className="mb-4 flex flex-row justify-center items-center">
            <Link mr="mr-2" href="https://docs.livecycle.io/">
              Documentation
            </Link>
            <hr className="border-l-1 border-solid border-black h-4" />
            <Link ml="ml-2" mr="mr-0" href="/terms-of-use">
              Terms of use
            </Link>
          </div>
          <div className="mb-14 flex flex-row justify-center items-center">
            <Link href="/privacy-policy">Privacy policy</Link>
          </div>
        </div>
      </nav>
      <div className="flex flex-row">
        <a href="https://www.linkedin.com/company/livecycleio" className="mr-9">
          <LinkedIn className="w-8 h-8 md:w-6 md:h-6 lg:w-8 lg:h-8" />
        </a>
        <a href="https://discord.gg/FunzKgErpT" className="mr-9">
          <Discord className="w-8 h-8 md:w-6 md:h-6 lg:w-8 lg:h-8" />
        </a>
        <a href="https://twitter.com/get_livecycle">
          <Twitter className="w-8 h-8 md:w-6 md:h-6 lg:w-8 lg:h-8" />
        </a>
      </div>
    </footer>
  )
}
