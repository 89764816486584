import React, { useCallback, useState } from "react"
import Logo from "../images/svg/logo.svg"
import { CTALink } from "./CTALink"
import { WithClassName } from "./types"

export type Section = "home" | "blog" | "about"

interface Props {
  currentSection: Section
  className?: string
  variant?: "default" | "grey"
}

const HamburgerIcon: React.FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 6.66675H26"
        stroke="#110E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 16H26"
        stroke="#110E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 25.3333H26"
        stroke="#110E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const Link: React.FC<WithClassName<{ href: string }>> = ({
  children,
  className,
  href,
}) => {
  return (
    <a className={`text-2xl mb-4 visited:text-black ${className}`} href={href}>
      {children}
    </a>
  )
}

const ExitIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18"
        stroke="#110E1E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke="#110E1E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const NavigationMenu: React.FC<{}> = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const openMenu = useCallback(() => {
    setIsMenuOpen(true)
    document.body.classList.add("overflow-hidden")
  }, [])

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false)
    document.body.classList.remove("overflow-hidden")
  }, [])

  return (
    <div className="block md:hidden">
      <button onClick={openMenu}>
        <HamburgerIcon />
      </button>
      {isMenuOpen && (
        <>
          <div
            className="fixed w-screen h-screen z-50 bg-black bg-opacity-30 top-0 left-0"
            onClick={closeMenu}
          />
          <div
            className="fixed h-screen bg-white top-0 z-50 rounded-3xl border-solid border-black border-4"
            style={{
              width: "calc(100vw - 50px)",
              height: "calc(100vh - 50px)",
              left: "25px",
              top: "25px",
            }}
          >
            <button
              onClick={closeMenu}
              className="absolute top-0 right-0 p-5 flex justify-center items-center w-auto"
            >
              <ExitIcon />
            </button>
            <div className="flex flex-col justify-between h-full px-8 py-10">
              <nav className="flex flex-col">
                <Logo className="mb-10" />
                <Link href="/about">About</Link>
                <Link href="https://livecycle.dev/">Log in</Link>
                <Link href="/blog">Blog</Link>
                <Link href="https://docs.livecycle.io/">Documentation</Link>
                <Link href="https://get-livecycle.notion.site/Senior-Software-Engineer-61e3d0bb46cd4997ae82bdb36cd967fd">
                  Careers
                </Link>
              </nav>
              <div>
                <CTALink
                  target="_blank"
                  href="https://livecycle.dev"
                  variant="solid"
                >
                  Try now!
                </CTALink>
                <CTALink
                  target="_blank"
                  href="https://calendly.com/d/cjk-9bz-6gj/livecycle-demo-session"
                  variant="outline"
                  className="mt-4"
                >
                  Book a demo
                </CTALink>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const NavBarLink: React.FC<
  React.HTMLProps<HTMLAnchorElement> & { isSelected?: boolean }
> = ({ children, isSelected = false, ...props }) => {
  return (
    <a
      {...props}
      className={`visited:${
        isSelected ? "text-lc-blue" : "text-black"
      } text-lg flex justify-center items-center ${
        isSelected ? "text-lc-blue" : "text-black"
      } ${props.className}`}
    >
      {children}
    </a>
  )
}

export const NavigationBar: React.FC<Props> = ({
  currentSection,
  variant = "default",
  className,
}) => {
  const mobileBg = variant === "default" ? "bg-white" : "bg-lc-grey-light"
  return (
    <nav
      className={`${mobileBg} sm:bg-white flex flex-row justify-between px-6 py-8 md:px-20 md:py-16 ${className}`}
    >
      <a href="/" className="flex justify-center items-center w-40 md:w-auto">
        <Logo className={`w-40 sm:w-auto `} />
      </a>
      <div className="grid-flow-col gap-11 self-center hidden md:grid">
        <NavBarLink isSelected={currentSection === "about"} href="/about">
          About
        </NavBarLink>
        <NavBarLink isSelected={currentSection === "blog"} href="/blog">
          Blog
        </NavBarLink>
        <NavBarLink href="https://docs.livecycle.io">Documentation</NavBarLink>
        <NavBarLink
          href="http://livecycle.dev"
          className="h-15 w-30 border-2 border-black"
        >
          Log in
        </NavBarLink>
      </div>
      <NavigationMenu />
    </nav>
  )
}
