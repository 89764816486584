import React from "react"

interface Props {
  variant?: "outline" | "outline-white" | "solid"
  widthClassName?: string
}

export const CTALink: React.FC<Props & React.HTMLProps<HTMLAnchorElement>> = ({
  variant = "solid",
  className = "",
  widthClassName = "w-50",
  children,
  ...props
}) => {
  let style: string

  switch (variant) {
    case "outline":
      style = "visited:text-black border-2 border-black"
      break
    case "outline-white":
      style = "visited:text-white text-white border-2 border-white"
      break
    case "solid":
      style = "visited:text-white text-white bg-lc-blue"
      break
  }
  return (
    <a
      {...props}
      className={`text-lg flex justify-center items-center ${widthClassName} h-15 ${style} ${className}`}
    >
      {children}
    </a>
  )
}
