import React from "react"
import type { WithClassName } from "./types"

export const Section: React.FC<
  WithClassName<{
    style?: React.CSSProperties
    limitMaxWidth?: boolean
    itemsLocation?: "items-center" | "items-start" | "items-end"
    padding?: string
  }>
> = React.memo(
  ({
    children,
    className = "",
    style,
    limitMaxWidth = true,
    itemsLocation,
    padding,
  }) => {
    return (
      <section
        style={style}
        className={`
          text-center
          ${limitMaxWidth ? "max-w-screen-xl" : ""}
          w-full 
          m-auto
          ${padding ?? "px-4"}
          flex flex-col justify-center
          ${itemsLocation ?? "items-center"} 
          ${className}`}
      >
        {children}
      </section>
    )
  }
)
