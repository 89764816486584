import React, { useState } from "react"
import { Section } from "../Section"

const DiscordLogo: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    width="42"
    height="32"
    viewBox="0 0 42 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.0176 2.67999C32.3417 1.428 29.4805 0.518103 26.4892 0C26.1218 0.66419 25.6926 1.55754 25.3967 2.2682C22.2168 1.78999 19.0662 1.78999 15.9449 2.2682C15.649 1.55754 15.2101 0.66419 14.8394 0C11.8449 0.518103 8.98036 1.43134 6.30445 2.68662C0.907108 10.8427 -0.556022 18.7962 0.175545 26.6368C3.75534 29.3101 7.2246 30.934 10.6353 31.9967C11.4774 30.8377 12.2285 29.6057 12.8755 28.3072C11.6432 27.839 10.463 27.2611 9.34779 26.5903C9.64364 26.3711 9.93304 26.142 10.2126 25.9062C17.0146 29.0876 24.405 29.0876 31.1257 25.9062C31.4086 26.142 31.6979 26.3711 31.9905 26.5903C30.872 27.2644 29.6886 27.8423 28.4563 28.3105C29.1033 29.6057 29.8511 30.8411 30.6965 32C34.1105 30.9373 37.583 29.3134 41.1628 26.6368C42.0212 17.5476 39.6964 9.6671 35.0176 2.67999ZM13.8022 21.8149C11.7603 21.8149 10.0858 19.9087 10.0858 17.5874C10.0858 15.2661 11.7246 13.3566 13.8022 13.3566C15.8799 13.3566 17.5543 15.2628 17.5186 17.5874C17.5218 19.9087 15.8799 21.8149 13.8022 21.8149ZM27.5361 21.8149C25.4943 21.8149 23.8198 19.9087 23.8198 17.5874C23.8198 15.2661 25.4585 13.3566 27.5361 13.3566C29.6138 13.3566 31.2883 15.2628 31.2525 17.5874C31.2525 19.9087 29.6138 21.8149 27.5361 21.8149Z"
      fill="#5865F2"
    />
  </svg>
)

const TwitterLogo: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    width="40"
    height="32"
    viewBox="0 0 40 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.3498 7.96632C35.3738 8.3135 35.3738 8.66067 35.3738 9.01105C35.3738 19.6872 27.2463 32 12.3848 32V31.9936C7.9947 32 3.69577 30.7425 0 28.3714C0.63836 28.4482 1.27992 28.4866 1.92308 28.4882C5.56125 28.4914 9.09543 27.2707 11.9576 25.0228C8.50026 24.9572 5.46845 22.703 4.40932 19.412C5.62044 19.6456 6.86837 19.5976 8.05709 19.2728C4.28773 18.5113 1.5759 15.1995 1.5759 11.3533C1.5759 11.3181 1.5759 11.2845 1.5759 11.2509C2.69903 11.8765 3.95655 12.2236 5.24287 12.262C1.69269 9.8894 0.598362 5.16649 2.74223 1.47393C6.84437 6.52161 12.8968 9.59022 19.394 9.91499C18.7428 7.10877 19.6324 4.16816 21.7314 2.19548C24.9856 -0.863526 30.1037 -0.706736 33.1627 2.54586C34.9722 2.18908 36.7065 1.52512 38.2936 0.584383C37.6904 2.45467 36.4281 4.04337 34.7418 5.0529C36.3433 4.86411 37.908 4.43534 39.3815 3.78098C38.2968 5.40648 36.9305 6.82239 35.3498 7.96632Z"
      fill="#1D9BF0"
    />
  </svg>
)

export const ContactUs: React.FC<{ className?: string }> = ({ className }) => {
  const [submitted, setSubmitted] = useState(false)
  const [formData, setFormData] = useState({})
  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  if (submitted) {
    return <div>Thanks! We'll be in touch</div>
  }
  return (
    <form
      className={`${className} text-black`}
      name="contact"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={async e => {
        e.preventDefault()
        const postData = {
          "form-name": (e.target as HTMLFormElement).getAttribute("name"),
          ...formData,
        }
        await fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: Object.entries(postData)
            .map(
              ([key, value]) =>
                encodeURIComponent(key) + "=" + encodeURIComponent(value)
            )
            .join("&"),
        })
        setSubmitted(true)
        return false
      }}
    >
      <p hidden>
        <input name="bot-field" onChange={handleChange} />
      </p>
      <div className="relative w-full">
        <input
          type="email"
          name="email"
          required={true}
          className="py-2 px-4 text-xl w-full"
          onChange={handleChange}
          placeholder="email"
        ></input>
        <button type="submit">
          <div className="flex absolute bottom-0 right-4 top-0 flex-col justify-center">
            <svg
              className=""
              width="140"
              height="24"
              viewBox="0 0 140 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 12H139" stroke="#FF00FF" strokeWidth="3" />
              <path d="M130 23L138 12L130 1" stroke="#FF00FF" strokeWidth="3" />
            </svg>
          </div>
        </button>
      </div>
    </form>
  )
}
export const BetterTogether = () => {
  return (
    <Section
      limitMaxWidth={false}
      className="bg-lc-grey border-t-4 border-b-4 border-solid border-black mt-30"
    >
      <div
        style={{ maxWidth: "1440px" }}
        className="flex flex-col sm:flex-row justify-items-start sm:text-left my-30 sm:my-40 w-screen"
      >
        <div className="sm:w-1/2 sm:pl-16 pb-20 sm:pb-0 border-b-4 border-black border-solid sm:border-b-0">
          <div className="flex flex-col justify-center">
            <h1 className="text-4xl lg:text-4xl xl:text-6xl sm:mr-40 mb-8">
              Let's build <br className="sm:hidden" />
              better,
              <br className="hidden sm:block" /> together.
            </h1>
            <h2 className="mt-2 lg:text-2xl  xl:text-3xl">
              Join the Livecycle community.
            </h2>
          </div>
          <div className="flex flex-col justify-center items-center sm:items-start">
            <a
              href="https://discord.gg/FunzKgErpT"
              style={{ width: 310 }}
              className="text-lc-black hover:no-underline mt-4 link:text-lc-black visited:text-lc-black block relative border-1 border-lc-black py-2 px-4 bg-white text-xl"
            >
              <DiscordLogo className="inline-block mx-2" />
              Join us on Discord
            </a>
            <a
              href="https://twitter.com/get_livecycle"
              style={{ width: 310 }}
              className="text-lc-black hover:no-underline mt-4 link:text-lc-black visited:text-lc-black block relative border-1 border-lc-black py-2 px-4 bg-white text-xl"
            >
              <TwitterLogo className="inline-block mx-2" />
              Follow us on Twitter
            </a>
          </div>
        </div>
        <div className="sm:w-1/2 pt-20 sm:pt-0 p-12">
          <div className="flex flex-col items-center sm:items-start">
            <h1 className="text-4xl mb-8 lg:text-4xl xl:text-6xl">
              Stay in the loop.
            </h1>
            <h2
              // style={{ paddingRight: "clamp(0px, 12%, 120px)" }}
              className="my-2 lg:text-2xl xl:text-3xl mb-12"
            >
              Get product updates that may actually change your life (as a
              developer). Spam not included.
            </h2>
          </div>

          <div
            className="self-center w-full"
            // style={{ paddingRight: "clamp(0px, 10%, 100px)" }}
          >
            <ContactUs />
          </div>
        </div>
      </div>
    </Section>
  )
}
